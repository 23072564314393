const studyYearsList = [
  "< Grade 11",
  "Grade 11",
  "Grade 12",
  "1st Year",
  "2nd Year",
  "3rd Year",
  "4th Year",
  "5th Year",
  "Grad School",
];
export const studyYears = studyYearsList.map((v) => ({ label: v, value: v }));
export default studyYears;
